import React, { useState, useEffect } from "react";
import { Container, Row, Col, ListGroup, Card, Form } from "react-bootstrap";
import { fetchScenes } from "../services/apiService"; // Adjust the path as necessary
import CurrentTime from "./CurrentTime"; // Adjust the path as necessary
import { onSnapshot, collection } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../services/firebaseConfig";

const MainDisplay = () => {
  const [scenes, setScenes] = useState([]);
  const [selectedScene, setSelectedScene] = useState(null);
  const [stations, setStations] = useState([]);
  const [selectedStation, setSelectedStation] = useState("");
  const [isLiveSync, setIsLiveSync] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const renderNextSceneTitle = () => {
    if (selectedScene) {
      const currentIndex = scenes.findIndex(
        (scene) => scene[""] === selectedScene[""]
      );
      const nextScene = scenes[currentIndex + 1]; // Get the next scene by index
      return nextScene ? nextScene.Scene : "End of Scenes";
    }
    return "NA";
  };
  const toggleAdminMode = () => {
    setIsAdminMode(!isAdminMode);
  };
  useEffect(() => {
    const loadData = async () => {
      const sceneData = await fetchScenes();
      setScenes(sceneData);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (scenes.length > 0) {
      const stationLabels = Object.keys(scenes[0]).filter((key) =>
        key.startsWith("#")
      );
      setStations(stationLabels);
    }
  }, [scenes]);

  const handleSceneSelect = (scene) => {
    setIsLiveSync(false);
    setSelectedScene(scene);
  };

  useEffect(() => {
    let unsubscribe = () => {};

    if (isLiveSync) {
      const currentSceneCollection = collection(db, "currentscene");
      unsubscribe = onSnapshot(currentSceneCollection, (snapshot) => {
        const sceneData = snapshot.docs.map((doc) => doc.data());
        console.log("sceneData", sceneData);
        // Assuming the document structure has a field 'scene' which is the ID of the scene
        const liveScene = scenes.find(
          (scene) => scene[""] === sceneData[0].scene.toString()
        );
        if (liveScene) setSelectedScene(liveScene);
      });
    }

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [isLiveSync, db, scenes]);

  // ...

  const handleSyncToLive = () => {
    // Enable live sync
    setIsLiveSync(true);
  };
  const renderStationsOptions = () => {
    return stations.map((station) => (
      <option key={station} value={station}>
        {station}
      </option>
    ));
  };

  const handleSetLive = async (sceneId) => {
    const currentSceneDoc = doc(db, "currentscene", "IpL0E6tOw2us8T97GjZV"); // Replace 'yourDocumentId' with the actual document ID
    await setDoc(currentSceneDoc, { scene: sceneId });
  };

  const renderSceneList = () => {
    return scenes.map((scene) => (
      <ListGroup.Item
        key={scene[""]}
        action
        onClick={() => handleSceneSelect(scene)}
        active={selectedScene && selectedScene[""] === scene[""]}
      >
        {scene.Scene}
        {isAdminMode && (
          <button onClick={() => handleSetLive(scene[""])}>Set Live</button>
        )}
      </ListGroup.Item>
    ));
  };

  const handleStationSelect = (event) => {
    setSelectedStation(event.target.value);
  };
  const renderNotes = () => {
    if (!selectedScene || !selectedStation) return null;

    // Split the notes string into an array and map over it to render ListGroup items
    const notes = selectedScene[selectedStation]?.split(",") || [];
    return notes.map((note, index) => (
      <ListGroup.Item key={index}>{note}</ListGroup.Item>
    ));
  };

  return (
    <Container fluid>
      <Row>
        <Col md={2} id="sidebar">
          <CurrentTime />
          <h3>Scene List</h3>
          <button
            onClick={handleSyncToLive}
            className="btn-primary btn"
            disabled={isLiveSync}
          >
            Sync to Live
          </button>
          <ListGroup>{renderSceneList()}</ListGroup>
        </Col>
        <Col md={10} id="main-content">
          <Row>
            <Col md={6} id="current-scene">
              <Card>
                <Card.Body>
                  <Card.Title>CURRENT SCENE</Card.Title>
                  <Card.Text>{selectedScene?.Scene}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} id="next-scene">
              <Card>
                <Card.Body>
                  <Card.Title>NEXT SCENE</Card.Title>
                  <Card.Text>{renderNextSceneTitle()}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12} id="notes">
              <h4>Select Station</h4>
              <Form.Select
                aria-label="Select station"
                onChange={handleStationSelect}
                value={selectedStation}
              >
                {renderStationsOptions()}
              </Form.Select>
              <h4> Things to Note for this Station</h4>
              <ListGroup>{renderNotes()}</ListGroup>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Form.Check
            type="checkbox"
            label="Admin Mode"
            checked={isAdminMode}
            onChange={toggleAdminMode}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default MainDisplay;
