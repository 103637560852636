import firebase from "firebase/app";
import "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
const firebaseConfig = {
  apiKey: "AIzaSyCHZqvI78tEg6htBmoGkEPOuvvRtGUjPVI",
  authDomain: "salvationapp-b0427.firebaseapp.com",
  projectId: "salvationapp-b0427",
  storageBucket: "salvationapp-b0427.appspot.com",
  messagingSenderId: "40212478902",
  appId: "1:40212478902:web:d61e39fb1e73f028f5df17",
  measurementId: "G-EHSWV92PL1",
};
// Initialize Firebase
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
