import axios from "axios";

const SHEETDB_API_URL = "https://sheetdb.io/api/v1/5x1qs9vlzmir9";

export const fetchScenes = async () => {
  try {
    const response = await axios.get(SHEETDB_API_URL);
    return response.data;
  } catch (error) {
    console.error("Error fetching scene data:", error);
    return [];
  }
};
