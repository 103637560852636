import logo from "./logo.svg";
import "./App.css";
import MainDisplay from "./screens/maindisplay";
import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <div className="App">
      <MainDisplay />
      <header className="App-header"></header>
    </div>
  );
}

export default App;
